import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import AlphaLogo from "../img/logo.png";
import { withRouter } from "react-router-dom";
import { MdLock } from "react-icons/md";

// COMPONENTS
import Button from "../components/buttons/Button";
import AlphaForm from "../components/forms/AlphaForm";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// REDUX
import { connect } from "react-redux";

// FUNCTIONS
import AJAX from "../functions/ajax";

export class ResetPasswordForm extends Component {
  _id = Math.random();

  state = {
    loader: false,
    confirmedAtVisibility: false,
    form: {
      errMsg: "",
      components: [
        {
          id: "523e90f",
          type: "password",
          name: "password",
          value: "",
          repeat: "firstPass",
          icon: <MdLock />,
          additional: {
            placeholder: "password",
          },
          validate: {
            valid: false,
            regex: AA_GLOBAL.userPassRegex,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterPassword",
              invalidValue: "userPasswordValidation",
            },
          },
        },
        {
          id: "s0647ev",
          type: "password",
          name: "password_confirmation",
          value: "",
          icon: <MdLock />,
          repeat: "secondPass",
          additional: {
            placeholder: "repeatPassword",
          },
          validate: {
            valid: false,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterPassword",
              repeatPassword: "repeatPassNotMatch",
            },
          },
        },
      ],
    },
  };

  componentWillUnmount() {
    AJAX().cancelRequest(this._id);
  }

  handleResetPassword = formData => {
    if (formData) {
      this.setState({ loader: true });

      AJAX()
        .resetPassword(this._id, this.props, formData)
        .then(res => {
          if (res) {
            // handle error
            if (res.status === "error") {
              this.setState(prevState => {
                return {
                  ...prevState,
                  loader: false,
                  form: { ...this.state.form, errMsg: res.key },
                };
              });
              return false;
            }

            this.setState({ loader: false });

            AA_GLOBAL.cookies.set("auth_token", res.data.data.auth_token, {
              path: "/",
              expires: AA_GLOBAL.authCookieExpireData,
            });

            this.props.history.push("/ui/");
          }
        });
    }
  };

  render() {
    const TagName =
      this.props.app.screenSize === "mobile" || this.props.app.screenSize === "tablet"
        ? "h1"
        : "TagName";

    return (
      <div className="loginMain__auth">
        <div
          className={
            "container container__wsLarge container__boxS loginMain__auth-con3 a-slideLeft"
          }
        >
          <div className="loginForm" id="loginPageForm">
            {/* <div data-test="coverLoader" className={"cover cover__loader loginForm__loader " + (this.state.loader ? '' : 'u-hide')} >
            <div className="circleLoader-extraLarge"></div>
          </div> */}

            {/* <div data-test="coverMsgContainer" className={"cover loginForm__confirm " + (this.state.confirmedAtVisibility ? '' : 'u-hide')}>
            <div className="loginForm__title">
            <img
              data-test="logo"
              src={AlphaLogo}
              alt="alpha awards logo"
              className="loginForm__title-logo"
            />
            <TagName className="title--big" data-test="title"><FormattedMessage id="resetPassword"/></TagName>
            </div>
            <div className="u-mb">
              {this.state.confirmEmailMsg ?
              <strong>
              <FormattedMessage id={this.state.confirmEmailMsg}/>.
              </strong>
              : null}
            </div>
          </div> */}

            <div className="loginForm__title" data-test="formTitleCon">
              <img
                data-test="logo"
                src={AlphaLogo}
                alt="alpha awards logo"
                className="loginForm__title-logo"
              />
              <TagName className="title--big" data-test="title">
                <FormattedMessage id="resetPassword" />
              </TagName>
            </div>

            <AlphaForm
              test="AlphaForm"
              form={this.state.form}
              getData={this.handleResetPassword}
              formId="reset"
            />

            <div className="loginForm__loginSeparator"></div>

            <Button
              type="submit"
              labelID="resetPassword"
              addClass="loginForm__form-btn"
              formID="reset"
              test="mainBtn"
            />
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  info: state.app.info,
  app: state.app,
});

export default injectIntl(withRouter(connect(mapStateToProps)(ResetPasswordForm)));
