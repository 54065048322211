import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosWithAuth } from "../axiosSetup";
import { FormattedMessage, injectIntl } from "react-intl";
import AlphaLogo from "../img/logo.png";
import AlphaLogoJudge from "../img/logo_judge.png";
import { withRouter } from "react-router-dom";
import { MdEmail, MdLock } from "react-icons/md";

// FUNCTIONS
import AJAX from "../functions/ajax";
import useResizeScreen from "../hooks/useResizeScreen";

// COMPONENTS
import Button from "../components/buttons/Button";
import IconAria from "../components/icons/IconAria";
import AlphaForm from "../components/forms/AlphaForm";
import ContentGenerator from "../components/layout/ContentGenerator";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// REDUX
import { connect } from "react-redux";
import { getSession } from "../actions/userActions";

export class Login extends Component {
  _id = Math.random();

  state = {
    loader: false,
    confirmedAtVisibility: false,
    form: {
      errMsg: "",
      components: [
        {
          id: "ffia243wd5",
          type: "text",
          name: "email",
          value: "",
          rememberMe: true,
          icon: <IconAria iconId="email" />,
          additional: {
            placeholder: "email",
          },
          validate: {
            valid: false,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterEmail",
              invalidValue: "invalidEmail",
            },
          },
        },
        {
          id: "20fd342888",
          type: "password",
          name: "password",
          value: "",
          icon: <IconAria iconId="password" />,
          additional: {
            placeholder: "password",
          },
          validate: {
            valid: false,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterPassword",
            },
          },
        },
      ],
    },
  };

  componentWillUnmount() {
    AJAX().cancelRequest(this._id);
  }

  getChangeAuth = name => () => this.props.changeAuth(name);

  handleLogin = formData => {
    if (!formData) return;

    this.setState({ loader: true });

    const loginPostData = {
      session: {
        email: formData.email,
        password: formData.password,
      },
      organization_id: this.props.info.context.organization_id,
      application_id: this.props.info.context.application_id,
    };

    // LOGIN
    AJAX()
      .login(this._id, this.props, loginPostData)
      .then(res => {
        if (!res || res.status === "error") {
          this.setState({ loader: false, form: { ...this.state.form, errMsg: "login_failure" } });
          return;
        }

        const {
          data: { auth_token },
        } = res.data;

        AA_GLOBAL.cookies.set("auth_token", auth_token, {
          path: "/",
          expires: AA_GLOBAL.authCookieExpireData,
        });
        // set authorization token in axiosWithAuth header
        axiosWithAuth.defaults.headers.authorization = auth_token;

        // show login form first on page reload
        // localStorage.setItem('login', true);

        AJAX()
          .getSession(this._id, this.props)
          .then(res => {
            const session = res.data;
            this.setState({ loader: false });
            this.props.getSession(session);

            const role = this.props.match.params.module || session.data.default_role;
            AA_GLOBAL.switchUserRole(role, this.props);
          });
      });
  };

  render() {
    const forms = this.props.data && this.props.data.forms;
    const isReviewer = ["judge", "reviewer"].includes(this.props.match.params.module);
    const TagName =
      this.props.app.screenSize === "mobile" || this.props.app.screenSize === "tablet"
        ? "h1"
        : "h2";

    return (
      <div className="loginForm" id="loginPageForm">
        <div
          data-test="coverLoader"
          className={
            "cover cover__loader  loginForm__loaderCon " + (this.state.loader ? "" : "u-hide")
          }
        >
          <div className="circleLoader-extraLarge loginForm__loader"></div>
        </div>

        <div
          data-test="coverMsgContainer"
          className={
            "cover loginForm__confirm " + (this.state.confirmedAtVisibility ? "" : "u-hide")
          }
        >
          <div className="loginForm__title">
            <img
              data-test="logo"
              src={isReviewer ? AlphaLogoJudge : AlphaLogo}
              alt="alpha awards logo"
              className="loginForm__title-logo"
            />
            <TagName className="title--big" data-test="title">
              <FormattedMessage id="login" />
            </TagName>
          </div>
          {forms && forms.header_message && forms.header_message.form === "login" && (
            <div className="">
              <ContentGenerator data={forms.header_message.html} />
            </div>
          )}

          <div className="u-mb">
            {this.state.confirmEmailMsg && (
              <strong>
                <FormattedMessage id={this.state.confirmEmailMsg} />.
              </strong>
            )}
          </div>
        </div>

        <div className="loginForm__title" data-test="formTitleCon">
          <img
            data-test="logo"
            src={isReviewer ? AlphaLogoJudge : AlphaLogo}
            alt="alpha awards logo"
            className="loginForm__title-logo"
          />
          <TagName className="title--big" data-test="title">
            <FormattedMessage id="login" />
          </TagName>
        </div>
        {forms && forms.header_message && forms.header_message.form === "login" && (
          <div className="">
            <ContentGenerator data={forms.header_message.html} />
          </div>
        )}
        <AlphaForm
          test="AlphaForm"
          form={this.state.form}
          getData={this.handleLogin}
          formId="login"
        />

        <div className="loginForm__loginSeparator"></div>

        <Button
          type="submit"
          tabIndex="6"
          labelID="login"
          addClass="loginForm__form-btn"
          formID="login"
          test="mainBtn"
        />

        {forms && !forms.hide_registration && (
          <Button
            labelID="register"
            tabIndex="7"
            clicked={this.getChangeAuth("register")}
            addClass="loginForm__form-btn button--secondary"
          />
        )}

        <div className="loginForm__footer" data-test="formFooter">
          {/* {forms && !forms.hide_registration &&
            <span className="button button__text" onClick={this.getChangeAuth('register')} data-test="registration"><FormattedMessage id="register"/>&nbsp;– </span>} */}
          <span
            className="button button__text r-showOnSmall"
            onClick={this.getChangeAuth("info")}
            data-test="info"
          >
            <FormattedMessage id="info" />
            &nbsp;–{" "}
          </span>
          <span
            tabIndex="10"
            className="button button__text"
            onClick={this.getChangeAuth("retrieve")}
            onKeyUp={e => e.key === "Enter" && this.props.changeAuth("retrieve")}
            data-test="forgotPassword"
          >
            <FormattedMessage id="forgotPassword" />
          </span>
          {/*{forms && !forms.hide_registration &&
            [<FormLink key="registerLink" msgID="register" onClick={this.getChangeAuth('register')} data-test="registration"/>, " – "]}
          <FormLink msgID="info" classes="r-showOnSmall" onClick={this.getChangeAuth('info')} data-test="info"/> –
          <FormLink msgID="forgotPassword" onClick={this.getChangeAuth('retrive')} data-test="forgotPassword"/>*/}
        </div>
      </div>
    );
  }
}

// function FormLink(p) {
//   return <span className={"btn btn__text" + (p.classes && p.classes || '')} onClick={p.onClick} data-test={p['data-test']}><FormattedMessage id={p.msgID}/></span>;
// }

Login.propTypes = {
  changeAuth: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = store => ({
  info: store.app.info,
  user: store.user,
  app: store.app,
});

export default injectIntl(withRouter(connect(mapStateToProps, { getSession })(Login)));
