import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withRouter, Prompt } from "react-router-dom";
import axios from "axios";
import allFormioForms from "./allFormioData.json";
import AA_GLOBAL from "../../globals/globals";

// COMPONENTS
import BaseFormio from "../forms/BaseFormio";
import ContentLoader from "../loaders/ContentLoader";
import ContentContainer from "../layout/ContentContainer";
import ContentGenerator from "../layout/ContentGenerator";
import ScrollBtn from "../navigations/ScrollBtn";
import Alerter from "../alerts/Alerter";
import Modal from "../modals/Modal";
import Button from "../buttons/Button";
import AlphaUppy from "../forms/AlphaUppy";
import Overview from "../display/Overview";

// REDUX
import { connect } from "react-redux";

// HOC
import AnimateHOC from "../../hoc/AnimateHOC";

// VENDORS
import fadeInOut from "../../vendors/fadeInOut";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import { classes } from "../../functions/utils";

import { safeDelay } from "../../hooks/useSafeDelay";

export class EntryStepDisplay extends Component {
  _id = Math.random();

  mounted = false;
  currentFormio = null; // current Formio instance
  nextRoute = null;
  safeDelay = ms => safeDelay(ms, () => this.mounted);
  ifMounted = f => (...args) => this.mounted && f(...args);

  requestID = x => `${this._id}/${x}`;
  overviewRequest = this.requestID("overview");
  stepRequest = this.requestID("step");
  patchRequest = this.requestID("patch");

  state = {
    hasChanges: false, // form has unsaved data?
    uploadFormData: {}, // used for AlphaUppy
    fetchedStep: null, // current step form + data
    blockRoute: false, // blocked by form changes?
    uppyRouteBlock: false, // blocked by Uppy?
    showLoader: false, // loading animation
  };

  //routeBlocked
  UNSAFE_componentWillReceiveProps(props) {
    if (props.uppyState === "upload_started" && !this.state.uppyRouteBlock)
      this.setState({ uppyRouteBlock: true });

    if (props.uppyState === "upload_success" && this.state.uppyRouteBlock) {
      // timeout is added so the rout is not unblocked
      // before the animation (slide right) finishes
      this.timeoutForRouteBlock = setTimeout(() => {
        this.setState({ uppyRouteBlock: false });
      }, 2000);
    }

    if (props.uppyState === "upload_file_remove" && this.state.uppyRouteBlock)
      this.setState({ uppyRouteBlock: false });


  }

  componentDidMount() {
    this.mounted = true;
    this.props.step && this.fetchStep(this.props.step);
    // scroll page to top on load
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const newStep = this.props.step,
      prevStep = prevProps.step;

    // Only apply changes if the data_set_id is different and if the route is not blocked.
    if (
      (!prevStep || (newStep && prevStep.data_set_id !== newStep.data_set_id)) &&
      !(this.state.uppyRouteBlock || this.state.blockRoute)
    ) {
      this.setState({ fetchedStep: null, updatedAlerts: null, overview: null }, () =>
        this.fetchStep(newStep)
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutForRouteBlock);
    this.mounted = false;
    this.currentFormio = null;
    AJAX().cancelRequests([this.overviewRequest, this.stepRequest, this.patchRequest]);
  }

  fetchStep(newStep) {
    newStep.is_last && this.fetchEntryOverview();
    this.fetchEntryDataSet(newStep);
  }

  fetchEntryOverview = () =>
    this.props.menu?.data_url &&
    AJAX()
      .get(this.overviewRequest, this.props.menu.data_url)
      .then(this.ifMounted(res => res && this.setState({ overview: res.data })));

  fetchEntryDataSet(step) {
    this.setState({ showLoader: true });

    return AJAX()
      .getEntryStepsDisplayData(this.stepRequest, this.props)
      .then(res => {
        if (!this.mounted || !res?.data?.data_set || this.props.step !== step) return;

        this.currentFormio = null;
   
        // save translation to redux
        if(res.data && res.data.schema && res.data.schema.i18n) {
          const setLang = AA_GLOBAL.cookies.get('lang') || "de"
 
          const selectLang = res.data.schema.i18n[setLang.substr(0,2).toLowerCase()] 
          if(selectLang) this.props.dispatch({type: "SAVE_UPPY_TRANSLATIONS", payload: res.data.schema.i18n.de})
        }

        this.setState({
          fetchedStep: res.data,
          updatedAlerts: res.data.alerts,
          showLoader: false,
        });

        return res;
      })
      .finally(this.ifMounted(() => this.setState({ showLoader: false })));
  }

  submitEntryDataSet = (btn, data) => {
    btn.showLoader = btn.disabled = true;
    this.setState({ blockRoute: true, blockButtons: true });

    const { step } = this.props,
      { data_set_url, data_set_id } = step;

    return (
      this.safeDelay(150)
        .then(_ => AJAX().patch(this.patchRequest, data_set_url, { data, data_set_id }))
        .then(res => {
          if (!this.mounted || !res || this.props.step !== step) return;

          // Revert success button background color and icon after a few seconds.
          const revertButton = this.ifMounted(() => this.setState({ btnSaved: null }));
          setTimeout(revertButton, 3000);

          // Finally unblock the route.
          this.setState(
            { blockRoute: false, hasChanges: false, btnSaved: btn, updatedAlerts: res.data.alerts },
            btn.goToID &&
              (() => this.safeDelay(500).then(() => this.pushOtherStepHistory(btn.goToID)))
          );

          this.props.onStepSave(step);

          return res;
        })
        // Unblock buttons in any case.
        .finally(
          this.ifMounted(() => {
            btn.showLoader = btn.disabled = false;
            this.setState({ blockButtons: false });
          })
        )
    );
  };

  handleBlockedNavigation = nextLocation => {
    document.querySelector(".sticky-inner-wrapper").style.transform = "initial";

    if (this.state.uppyRouteBlock || this.state.blockRoute) {
      fadeModalInOrOut(true);

      if (nextLocation.pathname !== this.props.match.url) this.nextRoute = nextLocation.pathname;

      return false;
    }

    return true;
  };

  changeEntryStep = this.ifMounted(() => {
    fadeModalInOrOut(false);

    this.setState({ blockRoute: false, uppyRouteBlock: false, hasChanges: false }, () =>
      this.pushNextRouteHistory()
    );
  });

  formioChanged = this.ifMounted(hasChanges =>
    this.setState({ blockRoute: hasChanges, hasChanges })
  );

  pushNextRouteHistory = () => this.props.history.push(this.nextRoute);

  pushOtherStepHistory = otherID =>
    this.props.history.push(
      `/ui/${this.props.user.role_selected}/edit-entry/${this.props.match.params.id}/${otherID}`
    );

  handleFormButtonClick = btn => {
    if (btn.disabled) return;

    if (btn.action === "submit") {
      const { type } = this.state.fetchedStep;
      const formio = this.currentFormio;
      const data = ["formio", "parent", "confirm"].includes(type)
        ? formio.data
        : (type === "upload" && this.state.uploadFormData) || {};

      this.submitEntryDataSet(btn, data).then(res => {
        if (!this.mounted || !res || !formio) return;

        !btn.goToID && formio.checkAndShowErrors();
        formio.clearHasChanges();
      });
    } else {
      if (btn.goToID) this.pushOtherStepHistory(btn.goToID);
    }
  };

  uppyFormChanged = this.ifMounted(listInfo =>
    this.setState({
      blockRoute: listInfo.changed,
      hasChanges: listInfo.changed,
      uploadFormData: listInfo.content,
    })
  );

  // RENDERING

  renderStepContent = () => {
    const { step } = this.props;
    const { fetchedStep, btnSaved, blockButtons, hasChanges } = this.state;
    const { data_set, schema } = fetchedStep;


    const alerts = this.state.updatedAlerts || data_set.alerts;

    // NB: some steps don't always have a valid schema yet.
    const formSchema = schema?.components ? schema : { components: [], ...schema };

   



    const buttons = (
      <div className="entryListNav__stopBtns">
        {data_set.buttons.map(btn => (
          <Button
            key={btn.id}
            {...btn}
            clicked={() => this.handleFormButtonClick(btn)}
            disabled={btn.disabled || blockButtons}
            iconID={(btn == btnSaved && "check") || btn.iconID}
            addClass={classes(
              btn.addClass,
              btn == btnSaved && "button--success",
              btn.isSubmit && "entryListNav__stopBtns_huge"
            )}
          />
        ))}

        {this.props.app.screenSize === "desktop" && step.is_last && (
          <ScrollBtn text={this.props.intl.formatMessage({ id: "scrollDown" })} />
        )}
      </div>
    );

    
    const stepContent =
      fetchedStep.type !== "upload" ? (
        <BaseFormio
          // NB: need to set a key so that switching quickly between steps
          // doesn't possibly load the content from the previous step
          // potentially causing data-loss on save (see #AAN-485).
          key={step.data_set_id}
          schema={formSchema} 
          content={data_set.content}
          options={this.props.info.default_formio_options}
          onCreate={formio => (this.currentFormio = formio)}
          onDifferent={isDiff => this.formioChanged(isDiff)}
        >
          {/* TODO: use new actions prop when backend provides them. */}
        </BaseFormio>
      ) : (
        // NB: this is a legacy component and can be removed eventually.
        <AlphaUppy
          key={step.data_set_id}
          id={fetchedStep.id}
          url={step.data_set_url}
          errors={data_set.errors}
          uppyChanged={this.uppyFormChanged}
          schema={formSchema}
          formData={data_set.content}
        />
        
      );

    return (
      <AnimateHOC animate={!!data_set}>
        <ContentContainer
          // NB: setting a key here to make sure previous form data/schema is cleared.
          key={step.data_set_id}
          sticky
          titleType="h1"
          title={fetchedStep.title}
          checked={step.confirmed}
          hasUnsavedData={this.state.uppyRouteBlock || hasChanges}
          // Commented out because it cuts off too much if there are many alerts.
          // alerterData={alerts}
        >
          <Alerter list={alerts} />
          {stepContent}
          {buttons}
        </ContentContainer>
      </AnimateHOC>
    );
  };

  render() {
    return (
      <div style={{ minHeight: "400px" }}>
        <Prompt
          when={this.state.uppyRouteBlock || this.state.blockRoute}
          message={this.handleBlockedNavigation}
        />

        {this.state.showLoader && <ContentLoader full title={this.props.step?.label} />}

        {this.state.overview && (
          <AnimateHOC animate={true}>
            <Overview overviewData={this.state.overview} />
          </AnimateHOC>
        )}

        {this.props.step && this.state.fetchedStep && this.renderStepContent()}

        <Modal
          id={modalID}
          promptModal
          intl
          yesMsg="leaveThePage"
          noMsg="stayOnPage"
          yes={this.changeEntryStep}
          msg="ignoreChanges"
        />
      </div>
    );
  }
}

const modalID = "5234f04kf235235240f7506";

const fadeModalInOrOut = bool => fadeInOut(document.getElementById(modalID), "500", bool);

const mapStateToProps = store => ({
  uppyState: store.entry.uppyState,
  info: store.app.info,
  user: store.user,
  app: store.app,
});

export default injectIntl(connect(mapStateToProps)(withRouter(EntryStepDisplay)));
