import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { MdDashboard, MdLibraryAdd, MdMenu } from "react-icons/md";
// import {IoIosStats} from 'react-icons/io';
import { FaUserCog } from "react-icons/fa";
import { withRouter } from "react-router-dom";

// COMPONENTS
import BoxBtnLarge from "../buttons/BoxBtnLarge";
import ToggleRenderHOC from "../../hoc/ToggleRenderHOC";
import Counter from "../display/Counter";
import IconAria from "../icons/IconAria";

// REDUX
import { connect } from "react-redux";
import { showMobMenu } from "../../actions/appActions";

// HOC
import RemoveWrapDiv from "../../hoc/RemoveWrapDiv";

export class MainBoxNav extends Component {
  componentDidUpdate() {}

  isTicketTool = () => this.props.info.apps[this.props.info.default_app].slug === "aat2020";

  renderNewEntryBtn = () => {
    const buttonTitleID = this.isTicketTool() ? "newRegistration" : "newEntry";

    if (this.props.submissionDeadline && !this.props.user.enforce_profile) {
      if (this.props.submissionDeadline.active) {
        return (
          <BoxBtnLarge
            link="/submitter/new-entry"
            addClass={
              "mainNav__btnsCon-btn " +
              (this.props.match.path === "/ui/submitter/new-entry"
                ? "button__superlarge--active"
                : "")
            }
            title={this.props.intl.formatMessage({ id: buttonTitleID })}
            subtitle={this.props.intl.formatMessage({ id: "createNowSubtitle" })}
          >
            <IconAria iconId="add" className="button__superlarge--icon" />
          </BoxBtnLarge>
        );
      } else {
        return (
          <BoxBtnLarge
            disabled
            addClass="mainNav__btnsCon-btn "
            title={this.props.intl.formatMessage({ id: buttonTitleID })}
            subtitle={this.props.intl.formatMessage({ id: "createNowSubtitle" })}
          >
            <IconAria iconId="admin" className="button__superlarge--icon" />
          </BoxBtnLarge>
        );
      }
    }
  };

  renderLogo = () => {
    const app = this.props.info.apps[this.props.info.default_app],
      logo = app.logo || { src: app.logo_url };

    return (
      <a
        href={logo.href || "/ui"}
        target={logo.target || (logo.href ? "_blank" : "")}
        rel={logo.rel || "noopener noreferrer"}
      >
        <img
          src={logo.src}
          title={logo.title || app.name}
          alt={logo.alt || "Logo"}
          style={logo.style}
          className={logo.class}
          width={logo.width}
          aria-label={this.props.intl.formatMessage({ id: "opensInNewTab" })}
          height={logo.height}
          srcSet={logo.srcset}
        />
      </a>
    );
  };

  submitterNav = () => (
    <RemoveWrapDiv>
      <div className="mainNav__logoCon column-sm">
        {/* MOB MENU BTN */}
        <ToggleRenderHOC
          hideOnRoute={["/ui/submitter/dashboard", "/ui/submitter/new-entry", "/ui/info"]}
        >
          <BoxBtnLarge
            addClass="mainNav__item mainNav__mobMenuBtn"
            clicked={this.props.showMobMenu}
            test="mobMenuBtn"
          >
            <IconAria iconId="menu" className="button__superlarge--icon" />
          </BoxBtnLarge>
        </ToggleRenderHOC>

        {/* COMPANY LOGO */}
        <div className="mainNav__logo">{this.renderLogo()}</div>
      </div>

      <div className="mainNav__btnsCon column-md">
        {/* DASHBOARD BTN */}
        <BoxBtnLarge
          link="/submitter/dashboard"
          addClass="mainNav__btnsCon-btn"
          title={this.props.intl.formatMessage({ id: "dashboard" })}
          subtitle={this.props.intl.formatMessage({
            id: this.isTicketTool() ? "overviewRegSubtitle" : "overviewSubtitle",
          })}
          test="dashboardBtn"
        >
          <IconAria iconId="dashboard" className="button__superlarge--icon" />
        </BoxBtnLarge>
        {/* NEW ENTRY BTN */}

        {this.renderNewEntryBtn()}
        {/* ENTRY COUNTER */}

        {this.props.submissionDeadline && this.props.submissionDeadline.active && (
          <Counter
            submissionDeadline={this.props.submissionDeadline}
            info={this.props.info}
            addClass="mainNav__btnsCon-btn"
          />
        )}
        {/* // ) : (
        //   <div className="mainNav__btnsCon-btn counter">
        //     <div className="counter__loader">
        //       <div className="circleLoader-large"></div>
        //     </div>
        //   </div> */}
        {/* // ) */}
      </div>
    </RemoveWrapDiv>
  );

  adminNav = () => (
    <RemoveWrapDiv>
      <div className="mainNav__logoCon column-sm">
        {/* MOB MENU BTN */}
        <ToggleRenderHOC
          hideOnRoute={[
            "/ui/submission_admin/dashboard",
            "/ui/submission_admin/dashboard/new-entry",
            "/ui/info",
          ]}
        >
          <BoxBtnLarge
            addClass="mainNav__item mainNav__mobMenuBtn"
            clicked={this.props.showMobMenu}
            test="mobMenuBtn"
          >
            <IconAria iconId="menu" className="button__superlarge--icon" />
          </BoxBtnLarge>
        </ToggleRenderHOC>

        {/* COMPANY LOGO */}
        <div className="mainNav__logo">{this.renderLogo()}</div>
      </div>

      <div className="mainNav__btnsCon column-md">
        <BoxBtnLarge
          link={"/submission_admin/dashboard"}
          addClass={
            `mainNav__btnsCon-btn ` +
            (this.props.location.pathname.split("/")[3] === "dashboard"
              ? "button__superlarge--active"
              : "")
          }
          title={this.props.intl.formatMessage({ id: "manage" })}
          subtitle={this.props.intl.formatMessage({ id: "manageSubtitle" })}
          test="dashboardBtn"
        >
          <IconAria iconId="admin" className="button__superlarge--icon" />
        </BoxBtnLarge>

        {/* <BoxBtnLarge
          link="/submission_admin/pay"
          addClass={"mainNav__btnsCon-btn " + (this.props.match.path === '/ui/submission_admin/pay' ? 'button__superlarge--active' : '')}
          title={this.props.intl.formatMessage({id: 'pay'})}
          subtitle={this.props.intl.formatMessage({id: 'createNow'})}
          test="voteBtn">
          <FaEuroSign className="button__superlarge--icon"/>
        </BoxBtnLarge>

        <BoxBtnLarge
          link="/submission_admin/moderate"
          addClass={"mainNav__btnsCon-btn " + (this.props.match.path === '/ui/submission_admin/moderate' ? 'button__superlarge--active' : '')}
          title={this.props.intl.formatMessage({id: 'moderate'})}
          subtitle={this.props.intl.formatMessage({id: 'createNow'})}
          test="voteBtn">
          <IoIosStats className="button__superlarge--icon"/>
        </BoxBtnLarge> */}

        {/* ENTRY COUNTER */}
        {/* {this.props.submissionDeadline ?
          <Counter submissionDeadline={this.props.submissionDeadline} addClass="mainNav__btnsCon-btn"/>
          : <div className="mainNav__btnsCon-btn counter"><div className="counter__loader"><div className="circleLoader-large"></div></div></div> } */}
      </div>
    </RemoveWrapDiv>
  );

  render() {
    return (
      <nav className="mainNav__back" role="navigation" data-test="nav">
        <div className="rowCon">
          <div className="mainNav">
            {this.props.user.role_selected === "submitter" ? this.submitterNav() : null}
            {this.props.user.role_selected === "config_admin" ? this.adminNav() : null}
            {this.props.user.role_selected === "submission_admin" ? this.adminNav() : null}
          </div>
        </div>
      </nav>
    );
  }
}

MainBoxNav.propTypes = {
  submissionDeadline: PropTypes.object,
  info: PropTypes.object,
  showMobMenu: PropTypes.func.isRequired,
  lang: PropTypes.object,
};

const mapStateToProps = state => ({
  submissionDeadline: state.user.appModule,
  categories: state.entry.categories,
  info: state.app.info,
  user: state.user,
});

export default injectIntl(connect(mapStateToProps, { showMobMenu })(withRouter(MainBoxNav)));
