export default {
  "en-US": {
    default: "- example -",
    yes: "Yes",
    no: "No",
    success: "Success",
    dashboard: "Dashboard",
    something_missing: "Invalid",
    overviewSubtitle: "My entries",
    overviewRegSubtitle: "My registrations",
    newEntry: "New Entry",
    newRegistration: "New Registration",
    editEntry: "Edit",
    payEntries: "Pay entries",
    createNowSubtitle: "Create now",
    just: "Just",
    to: "until",
    day: "day ",
    days: "days ",
    new: "new",
    transferred: "transferred",
    submissionDeadline: "submission deadline",
    registrationDeadline: "registration deadline",
    deadlineExpired: "Deadline Expired",
    expired: "Expired",
    copy: "Copy",
    delete: "Delete",
    opensInNew: "opens in new window",
    remove: "Remove",
    restore: "Restore",
    removeFilter: "Remove Filter",
    removeAll: "Remove all",
    restoreAll: "Restore all",
    primary: "primary",
    secondary: "secondary",
    //whichNav: "{name} navigation",
    expand: "Expand",
    updated: "Updated",
    created: "Created",
    inProgress: "In Progress",
    notStarted: "Not yet started",
    excludeMsg: "If you should not vote, please confirm by clicking on the button below.",
    excludeMe: "Exclude me",
    includeMsg: "If you should vote, please confirm by clicking on the button below.",
    includeMe: "Include me",
    done: "done",
    evaluationRounds: "Evaluation Rounds",
    evaluationCategories: "Evaluation Categories",
    projectdetails: "Detailed overview",
    print: "Print",
    printNotSupported: "The print option is not supported in this browser.",
    checkSomething: "You have to check something first.",
    pay: "Pay",
    vote: "Voting",
    manage: "Administration",
    manageSubtitle: "All entries",
    moderate: "Moderation",
    entries: "Entries",
    users: "Users",
    cases: "Cases",
    review_results: "Results",
    addUser: "Add user",
    payments: "Payments",
    payed: "Paid",
    payedEntries: "Paid entries",
    payWithBank: "Pay via Bank Account",
    payWithCard: "Pay by Credit Card",
    payWithPaypal: "Pay via Paypal",
    price: "Price",
    tax: "Tax",
    submit: "Submit",
    create: "Create",
    save: "Save",
    saveAndContinue: "Save and continue",
    andContinue: "and continue",
    edit: "Edit",
    close: "Close",
    reset: "Reset",
    back: "Back",
    opensInNewTab: "Opens in a new tab.",
    confirmed: "Confirmed",
    confirmSteps: "Please confirm this steps first",
    unauthenticated_access: "Not authenticated",
    unsavedChanges: "Unsaved changes",
    ignoreChanges: "You didn't save your changes. Do you want to leave this page without saving?",
    leaveThePage: "Leave this page",
    stayOnPage: "Stay on this page",
    unsavedMsg: "Fill in all the necessary fields and press “Save” or “Save and continue”.",
    stepErrMsg: "Please complete the following steps to be able to continue:",
    drafts: "Drafts",
    published: "Published",
    submitted: "Submitted",
    total: "Total",
    totalCount: "Total",
    audioCount: "Audios",
    videoCount: "Videos",
    imageCount: "Images",
    files: "Files",
    deleteAllUploadItems: "Are you sure you want to delete all uploaded files?",
    listIsRemovedAfterSave: "This list will be removed after saving:",
    projectOverview: "Detailed overview",
    paymentOverview: "Payment overview",
    invitation_sent: "The user was created successfully.",
    invitation_already_sent: "The user has already been created.",
    projectList: "Entries",
    billingAddress: "Billing address",
    firstName: "First name",
    lastName: "Last name",
    companyAddress: "Company Address",
    company: "Company",
    UIDnumber: "UID-Nummer",
    profile: "Profile",
    noEntriesInTable: "No entries!",
    youHaveNoEntries:
      "You don't have any entries at the moment. Click on “New Entry” to get started!",
    youHaveNoRegistrations:
      "You don't have any registrations at the moment. Click on “New Registration” to get started!",
    starts: "starts",
    ends: "ends",
    daysLeft: "Days left",
    categories: "categories",
    user_updated: "Updated successfully.",
    successfullyRemoved: "Successfully removed",
    noResultsFound: "No results found",
    userInfo: "User Info",
    changePassword: "Change Password",
    profile_updated: "The profile has been saved.",
    resetPassword: "Reset Password",
    no_reset_token: "A valid reset token was not found",
    reset_token_session_error: "Couldn't reset password and create session with reset token",
    address: "Address",
    place: "Place",
    newsletterSubscriptions: "Newsletter subscriptions",
    privacyInfo: "Privacy Information",
    download: "Download",
    loading: "Loading",
    invoice: "invoice",
    viewInvoices: "View invoices",
    info: "Info",
    email: "Email",
    repeatPassword: "Repeat Password",
    reset_request_password: "Email with reset link has been sent",
    requestNewPassword: "Request Password Reset",
    toLogin: "Back to Login",
    pwd_change_failure: "Failed changing the password",
    pwd_change_success: "Password successfully changed",
    password: "Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    forgotPassword: "Forgot Password?",
    register: "Register",
    login: "Login",
    logout: "Logout",
    login_failure: "Login failed",
    cantLogin: "Can't Login?",
    areYouSureDeleteEntries: "Are you sure you want to delete this entry?",
    completeSubmission: "In order to complete the submission, you must pay the submission fee.",
    forgotPassMsg:
      "Forgot your password? No problem, we will fix it. Just type your email below and we will send you password recovery instruction to your email. Follow easy steps to get back to your account.",
    enterEmail: "Please enter your email.",
    invalidEmail: "Invalid email.",
    enterEmailAndPass: "Please enter your email and password.",
    email_registered_success:
      "Please check your inbox and click the confirmation link to continue to the application. Also please be aware that the email may land in your spam folder or that it may take some time until it arrives",
    email_already_registered:
      "This email address is already registered. If you can't remember your password you can use the 'forgot password' button to receive password reset instructions.",
    enterPassword: "Please enter your password.",
    passwordLong: "Password is too long.",
    passwordShort: "Password is too short.",
    repeatPassEmpty: "Please repeat your password.",
    repeatPassNotMatch: "Passwords do not match.",
    pleaseFillOut: "Please fill out this field.",
    search: "Search",
    searching: "Searching",
    setCheckbox: "Please check this checkbox if you want to proceed.",
    fillOutForm: "Please fill out the form and check Terms and Privacy!",
    powerdBy: "Powered by",
    faq: "FAQ",
    imprint: "Publication details",
    terms: "Accept Terms Of Service",
    acceptTerms: "Please accept the Terms of Service.",
    privacy: "Accept Privacy Protection Notice",
    acceptPrivacy: "Please accept the Privacy Notice.",
    userPasswordValidation: "Minimum 8 characters and at least one number and one letter (A-Z).",
    addNewEntry: "New Entry",
    scrollDown: "Scroll down to submission",
  },
  "de-DE": {
    default: "- example -",
    test: "Active {what}",
    yes: "Ja",
    no: "Nein",
    Nein: "Nein",
    UIDnumber: "UID-Nummer",
    acceptPrivacy: "Bitte akzeptieren Sie die Datenschutzerklärung.",
    acceptTerms: "Bitte akzeptieren Sie die Nutzungsbedingungen.",
    address: "Adresse",
    areYouSureDeleteEntries: "Sind Sie sicher, dass Sie dieses Projekt löschen wollen?",
    back: "Zurück",
    billingAddress: "Rechnungsadresse",
    cantLogin: "Haben Sie Ihr Passwort vergessen?",
    changePassword: "Passwort ändern",
    checkSomething: "Sie müssen zuerst etwas auswählen.",
    close: "Schließen",
    company: "Firma",
    companyAddress: "Firmenadresse",
    completeSubmission: "Um die Einreichung abzuschließen, müssen Sie die Einreichgebühr bezahlen.",
    confirmNewPassword: "Neues Passwort bestätigen",
    confirmSteps: "Bitte füllen Sie diese Schritte aus bevor sie fortfahren können",
    copy: "Kopieren",
    createNowSubtitle: "Jetzt erstellen",
    dashboard: "Übersicht",
    something_missing: "Ungültig",
    updated: "Aktualisiert",
    day: "Tag ",
    days: "Tage ",
    deadlineExpired: "Bewertungsfrist abgelaufen",
    expired: "Abgelaufen",
    delete: "Löschen",
    opensInNew: "öffnet in neuem Fenster",
    download: "Herunterladen",
    edit: "Bearbeiten",
    email: "Email",
    drafts: "Entwürfe",
    published: "Veröffentlicht",
    submitted: "Eingereicht",
    total: "Gesamt",
    totalCount: "Gesamt",
    audioCount: "Audios",
    videoCount: "Videos",
    imageCount: "Bilder",
    files: "Dateien",
    expand: "Erweitern",
    created: "Erstellt",
    inProgress: "In Bearbeitung",
    notStarted: "Noch nicht angefangen",
    excludeMsg: "Sollten Sie nicht abstimmen, bestätigen Sie dies bitte mit einem Klick auf den untenstehenden Button.",
    excludeMe: "Mich ausschließen",
    includeMsg: "Wenn Sie abstimmen sollten, bestätigen Sie dies bitte mit einem Klick auf den untenstehenden Button.",
    includeMe: "Schließ mich ein",
    done: "fertig",
    evaluationRounds: "Bewertungsrunden",
    evaluationCategories: "Bewertungskategorien",
    projectdetails: "Detailübersicht",
    stepErrMsg: "Bitte füllen Sie folgende Schritte aus, damit sie fortfahren können:",
    confirmed: "Eingereicht",
    opensInNewTab: "Wird in einem neuen Tab geöffnet.",
    unsavedMsg:
      "Füllen Sie alle erforderlichen Felder aus und klicken Sie auf „Speichern“ oder „Speichern und weiter“.",
    email_already_registered:
      "Diese Email-Adresse ist bereits registriert. Wenn Sie sich nicht an Ihr Passwort erinnern können, können Sie die Schaltfläche 'Passwort vergessen' verwenden, um Anweisungen zum Zurücksetzen des Passworts zu erhalten.",
    email_registered_success:
      "Die Zusendung des Registrierungs-Links kann eventuell einige Minuten dauern. Sollten Sie keinen Link erhalten, überprüfen Sie bitte auch Ihren Spam-Ordner/Werbungs-Ordner",
    enterEmail: "Bitte geben Sie ihre E-Mail-Adresse ein",
    enterEmailAndPass: "Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein.",
    enterPassword: "Bitte geben Sie Ihr Passwort ein.",
    faq: "HGF",
    fillOutForm: "Bitte füllen Sie das Formular aus und prüfen Sie die AGB und Datenschutz!",
    firstName: "Vorname",
    forgotPassMsg:
      "Kein Problem! Bitte geben Sie Ihre E-Mail-Adresse in das Feld ein und wir senden Ihnen eine Anleitung zur Passwortwiederherstellung an Ihre E-Mail-Adresse.",
    forgotPassword: "Passwort vergessen?",
    imprint: "Impressum",
    info: "Info",
    invalidEmail: "Ungültige E-Mail.",
    invoice: "Rechnung",
    just: "Nur noch",
    lastName: "Nachname",
    loading: "Wird geladen",
    login: "Login",
    login_failure: "Anmeldung fehlgeschlagen",
    logout: "Ausloggen",
    addNewEntry: "Neues Projekt",
    new: "neu",
    newEntry: "Neues Projekt",
    newRegistration: "Neue Anmeldung",
    editEntry: "Bearbeiten",
    vote: "Abstimmung",
    manage: "Verwalten",
    manageSubtitle: "Alle Einträge",
    moderate: "Moderieren",
    newPassword: "Neues Kennwort",
    newsletterSubscriptions: "Newsletter-Abos",
    leaveThePage: "Diese Seite verlassen",
    stayOnPage: "Auf dieser Seite bleiben",
    no_reset_token: "Es wurde kein gültiges Reset-Token gefunden",
    oldPassword: "Altes Passwort",
    overviewSubtitle: "Meine Projekte",
    overviewRegSubtitle: "Meine Anmeldungen",
    password: "Passwort",
    passwordLong: "Passwort ist zu lang.",
    passwordShort: "Das Passwort ist zu kurz.",
    pay: "Bezahlen",
    entries: "Projekte",
    users: "Benutzer",
    cases: "Fälle",
    review_results: "Ergebnisse",
    addUser: "Nutzer hinzufügen",
    payEntries: "Zahlen Sie Einträge",
    payWithBank: "Bezahlen mit Bankkonto",
    payWithCard: "Bezahlen mit Kreditkarte",
    payWithPaypal: "Bezahlen mit Paypal",
    payed: "Bezahlt",
    payedEntries: "Bezahlbare Einträge",
    paymentOverview: "Zahlungsübersicht",
    invitation_sent: "Der Benutzer wurde erfolgreich angelegt.",
    invitation_already_sent: "Der Benutzer wurde bereits angelegt.",
    deleteAllUploadItems: "Sind Sie sicher, dass Sie alle Uploads löschen wollen?",
    listIsRemovedAfterSave: "Diese Liste wird nach dem Speichern entfernt:",
    payments: "Zahlungen",
    place: "Ort",
    pleaseFillOut: "Bitte füllen Sie dieses Feld aus.",
    powerdBy: "Unterstützt von",
    price: "Preis",
    print: "Drucken",
    printNotSupported: "Die Druckoption wird in diesem Browser nicht unterstützt.",
    privacy: "Akzeptieren Sie die Datenschutzerklärung",
    privacyInfo: "Datenschutzerklärung",
    profile: "Profil",
    noEntriesInTable: "Keine Projekte!",
    youHaveNoEntries:
      "Sie haben momentan keine Projekte. Klicken Sie auf „Neues Projekt“ um eine Einreichung zu starten!",
    youHaveNoRegistrations:
      "Sie haben momentan keine Anmeldungen. Klicken Sie auf „Neue Anmeldung“, um eine anzulegen!",
    starts: "beginnt",
    ends: "endet",
    daysLeft: "Tage übrig",
    categories: "Kategorien",
    user_updated: "Erfolgreich aktualisiert.",
    projectList: "Projekte",
    projectOverview: "Detailübersicht",
    pwd_change_failure: "Das Passwort konnte nicht geändert werden",
    pwd_change_success: "Passwort erfolgreich geändert",
    register: "Registrieren",
    remove: "Entfernen",
    restore: "Wiederherstellen",
    removeAll: "Alle entfernen",
    restoreAll: "Alle wiederherstellen",
    removeFilter: "Filter entfernen",
    repeatPassEmpty: "Bitte wiederholen Sie Ihr Passwort.",
    repeatPassNotMatch: "Passwort stimmt nicht überein.",
    repeatPassword: "Wiederhole das Passwort",
    requestNewPassword: "Passwortwiederherstellung starten",
    reset: "Zurücksetzen",
    profile_updated: "Das Profile wurde gespeichert.",
    resetPassword: "Passwort zurücksetzen",
    reset_request_password:
      "Wir haben Ihnen eine E-Mail geschickt. Bitte gehen Sie in Ihr E-Mail Postfach und folgen den Anweisungen in der E-Mail",
    reset_token_session_error:
      "Passwort konnte nicht zurückgesetzt und Sitzung mit Reset-Token erstellt werden",
    create: "Erstellen",
    save: "Speichern",
    saveAndContinue: "Speichern und weiter",
    andContinue: "und weiter",
    unsavedChanges: "Ungespeicherte Änderungen",
    ignoreChanges:
      "Sie haben dieses Formular noch nicht gespeichert. Wollen Sie diese Seite verlassen, ohne es zu speichern?",
    search: "Suche",
    searching: "Suchen",
    setCheckbox: "Bitte aktivieren Sie das Kontrollkästchen.",
    submissionDeadline: "zum Einreichschluss",
    registrationDeadline: "zum Anmeldeschluss",
    submit: "Einreichen",
    success: "Erfolg",
    successfullyRemoved: "Erfolgreich entfernt",
    noResultsFound: "Keine Ergebnisse gefunden",
    tax: "MwSt.",
    terms: "Akzeptieren Sie die Nutzungsbedingungen",
    to: "bis",
    toLogin: "Zur Anmeldung",
    transferred: "übertragen",
    unauthenticated_access: "Nicht berechtigt",
    admin: "Admin",
    userInfo: "Benutzerinformation",
    userPasswordValidation:
      "Minimum sind 8 Zeichen und mindestens eine Zahl und ein Buchstabe (A-Z).",
    viewInvoices: "Rechnungen anzeigen",
    scrollDown: "Nach unten blättern bis zur Einreichung",
  },
};
