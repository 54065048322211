import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import AJAX from "../../functions/ajax";
import SortableList from "../lists/SortableList";
import ColorAlerter from "../alerts/ColorAlerter";
import { classes } from "../../functions/utils";
import { safeDelay } from "../../hooks/useSafeDelay";
import store from "../../store";

const Uppy = require("@uppy/core");
const Tus = require("@uppy/tus");
const { Dashboard } = require("@uppy/react");

class Uppyy extends Component {
  elemRef = React.createRef();
  elem = () => this.elemRef.current;
  safeDelay = ms => safeDelay(ms, () => this.mounted);

  defaultLocale = "de_DE";
  // maps unsupported locales to supported ones
  supportedLocalesMap = {
    en_GB: "en_US",
  };

  state = { list: this.props.uploads || [] };

  translations = {}

  constructor(props) {
    super(props);
    
    const entry = store.getState().entry
    if(entry && entry.translations) {
      this.translations = entry.translations
    }

    const { schema, config } = props;

    const configCopy = JSON.parse(JSON.stringify(config || {}));

    const componentConfig = schema.config || {};
    // Override general config with component config. Doesn't deep merge yet.
    const uppyConfig = { ...configCopy.uppy, ...componentConfig.uppy };

    // Pick a locale from the common configuration. Default to de_DE.
    let locale = uppyConfig.locale || this.defaultLocale;
    if (typeof locale === "string") {
      locale = this.supportedLocalesMap[locale] || locale;
      uppyConfig.locale = require("@uppy/locales/lib/" + locale);
    }

    // Clone shared tusConfig first.
    const thisTusConfig = configCopy.tus;
    // Add key, dir and jpath to the upload-source header.
    thisTusConfig["headers"]["upload-source"] = JSON.stringify(
      (({ key, dir, fpath, jpath }) => ({ key, dir, fpath, jpath }))(schema)
    );

    // Construct Uppy.
    this.uppy = Uppy(uppyConfig)
      .use(Tus, thisTusConfig)
      .on("upload-success", this.onUploadSuccess)
      .on("upload", this.onStartUploading)
      .on("upload-progress", this.onUploadProgress)
      .on("upload-error", this.onUploadError)
      .on("restriction-failed", (file, error) => this.showErrMsg(error.message))
      .on("file-removed", file => _.isEmpty(this.uppy.getState().files) && this.hideErrMsg());

    this.maxFiles = uppyConfig.restrictions?.maxNumberOfFiles;
    this.state.heightClass = this.getHeightClass(this.maxFiles);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploads !== this.props.uploads) this.setState({ list: this.props.uploads || [] });
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.uppy.close();
  }

  onUploadError = (file, error, _response) => {
    const m = (error.message || "").match(/response code: (.+?), response text: ([\s\S]+)\)$/m);
    const [code, respMsg] = m ? m.slice(1) : ["500", ""];
    const errorMsg = code !== "500" ? respMsg : "Internal server error";
    this.showErrMsg(`${this.uppy.getState().info.message} (#${code}: ${errorMsg})`);
  };

  onStartUploading = data => {
    store.dispatch({ type: "UPPY_STATE", payload: "upload_started" });
  };

  onUploadProgress = data => {
    store.dispatch({ type: "UPPY_STATE", payload: "upload_progress" });
  };

  onUploadSuccess = (file, response) => {
    store.dispatch({ type: "UPPY_STATE", payload: "upload_success" });

    Promise.all([
      AJAX()
        .get(file.id, response.uploadURL + "/json")
        .then(res => res?.data && this.state.list.unshift(res.data)),
      // Start the CSS transition animation in 500ms and allow it to finish in 500ms.
      this.animateFinishedFileCard(file.id, 500).then(() => this.safeDelay(500)),
    ])
      .then(() => {
        this.uppy.removeFile(file.id);
        this.listChanged({ list: this.state.list });
      })
      .catch(e => this.showErrMsg(`Error: ${file.name}: ${e && e.message}`));
  };

  animateFinishedFileCard = (fileID, delay) => {
    const fileCard = document.getElementById(`uppy_${fileID}`);
    return this.safeDelay(delay || 0).then(() => fileCard.classList.add("uppy-animate"));
  };

  listChanged = ({ list }) => {
    this.setState({ list }, () => (this.props.onListChanged || (() => {}))({ list }));
  };

  getHeightClass = maxFiles =>
    "uppyy__uploader-height" + (maxFiles <= 3 ? 1 : maxFiles <= 6 ? 2 : 3);

  showOrHideError = msg => {
    const innerWrap = this.elem().querySelector(".uppy-Dashboard-innerWrap");
    innerWrap && innerWrap.classList.toggle("uppyy__errBorder", msg !== null);
    this.setState({ errorMsg: msg });
  };

  showErrMsg = this.showOrHideError;
  hideErrMsg = () => {
    store.dispatch({ type: "UPPY_STATE", payload: "upload_file_remove" });
    this.showOrHideError(null);
  };

  render() {
    const { schema, addClass } = this.props;
   
    const listHeader = schema.listHeader || schema.label;
    const { list, heightClass, errorMsg } = this.state;

    const dashboard = this.uppy.getPlugin("react:Dashboard");
    if (_.isNumber(this.maxFiles) && dashboard)
      dashboard.setOptions({ disabled: list.length >= this.maxFiles });

    return (
      <div className={classes("uppyy__con", addClass)} ref="component">
        {_.isString(schema.label) && schema.hideLabel !== true && (
          <div className="uppyy__label">

            {/* <label
              htmlFor={this.props.id}
              className={classes("col-form-label", schema.validate?.required && "field-required")}
              dangerouslySetInnerHTML={{ __html: schema.label }}
            ></label> */}
            {/* {console.log(this.translations, schema.label, this.translations[schema.label])} */}
            <label
              htmlFor={this.props.id}
              className={classes("col-form-label", schema.validate?.required && "field-required")}
           
            >{this.translations[schema.label] || schema.label}</label>
          </div>
        )}
        <div id={this.props.id} className="uppyy" ref={this.elemRef}>
          <div className={classes("uppyy__uploader", heightClass)}>
            
            <Dashboard
              uppy={this.uppy}
              metaFields={schema.config?.uppy?.metaFields}
              proudlyDisplayPoweredByUppy={false}
            />
            {errorMsg && <div className="uppyy__err">{this.translations[errorMsg] || errorMsg}</div>}
          </div>
          <div className={classes("uppyy__list", heightClass)}>
            {_.isString(listHeader) && <ColorAlerter toggle warning text={this.translations[schema.listHeader] || listHeader} />}
            {_.isArray(list) && <SortableList items={list} onListChanged={this.listChanged} />}
          </div>
        </div>
        {_.isString(schema.description) && (
          // <p className="uppyy__desc" dangerouslySetInnerHTML={{ __html: schema.description }}></p>
          <p className="uppyy__desc">
            {this.translations[schema.description] || schema.description}
          </p>
        )}
      </div>
    );
  }
}

Uppyy.propTypes = {
  onListChanged: PropTypes.func,
  readOnly: PropTypes.bool, // Use in the future when needed.
};

export default Uppyy;
