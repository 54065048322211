import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { MdArrowDropUp, MdArrowDropDown, MdSearch, MdClose } from "react-icons/md";
import { MdImage, MdInsertDriveFile /*, MdLibraryMusic, MdVideoLibrary*/ } from "react-icons/md";
import { FaCopy, FaTrash, FaPrint } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import jp from "jsonpath";
import Pagination from "react-js-pagination";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";

// REDUX
import { connect } from "react-redux";
import { addEntryList } from "../../actions/entryActions";

// COMPONENTS
import ContentContainer from "../layout/ContentContainer";
import CheckBox from "../inputs/CheckBox";
import Modal from "../modals/Modal";
import TextAlerter from "../alerts/ColorAlerter";
import ContentLoader from "../loaders/ContentLoader";
import Select from "../inputs/Select";
import Popover from "../popovers/Popover";
import ColorAlerter from "../alerts/ColorAlerter";
import Button from "../buttons/Button";
import IconAria from "../icons/IconAria";
import Icon from "../icons/Icon";

import TableForm from "./TableForm";

// VENDORS
import fadeInOut from "../../vendors/fadeInOut";
import { formatDateTime } from "../../vendors/calTimeZone";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import { classes, wrapArray, debounce } from "../../functions/utils";

class AlphaTable extends Component {
  _id = Math.random();

  state = {
    tbodyLoader: false,
    alertVisiblity: false,
    rowIDs: [],
    listCheckBox: false,
    tableID: Math.random(),
  };

  _tableLoaded = false;

  postData = {};
  orderClicks = {};
  searchInputRef = React.createRef();

  UNSAFE_componentWillMount() {
    const table = this.props.data;
    if (table && table.data.length > 0) this.init(table);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const table = nextProps.data;
    if (table && table.data.length > 0) this.init(table);
  }

  // FUNCTIONS

  init = table => {
    this._tableLoaded = true;

    const initOrder = table.initial_order || [];
    this.postData = { filter: {}, search: "", order: initOrder, page: table.page_number };

    this.addCheckboxes(table);
    this.setColumnWidths(table);

    this.setState({ tableData: table });
  };

  fetchNewTable = postData => {
    // show loader
    // FIXME: creates jerky behaviour, since the loader icon replaces all the rows.
    // this.setState({tbodyLoader: true});

    const {
      tableData: { fetch_api },
    } = this.state;

    return AJAX()
      .sendAPI(this._id, { ...fetch_api, data: { ...fetch_api.data, ...postData } })
      .then(res => {
        const newTable = res && res.data;

        if (!newTable) return;

        this.postData = postData;
        this.addCheckboxes(newTable);
        this.setColumnWidths(newTable);

        this.setState({
          tbodyLoader: false,
          tableData: newTable,
          noResultsFound: newTable.data.length === 0,
          somethingIsChecked: false,
        });

        return newTable;
      });
  };

  addCheckboxes = table => {
    // if one of the buttons has multiple set to true, render the checkboxes.
    if (_find(table.batch_post_actions || [], { multiple: true })) {
      // add checked prop to each row
      table.data.forEach(row => (row.checked = false));

      // add check box to the first head element
      if (table.columns[0].key !== "checkAll")
        table.columns.unshift({ key: "checkAll", checked: false });
    }
  };

  setColumnWidths = table => {
    const numOfItems = table.columns.length - 1;
    const columnPercentage = 100 / numOfItems;
    const columnWidthBig = columnPercentage * 1.5 + "%";
    const columnWidthMedium = columnPercentage * 0.7 + "%";

    table.columns.forEach(column => {
      // if the column is title of category add fixed width
      if (column.key === "title" || column.key === "collections") {
        column.size = columnWidthBig;
      } else if (column.key === "code") {
        column.size = columnWidthMedium;
      } else if (column.key === "assets") {
        column.size = columnWidthMedium;
      }
    });
  };

  handleAction = action => {
    if (action.key === "delete") {
      // set button endpoint in the state
      this.setState({ buttonEndpointUrl: action.api_url });

      const el = document.getElementById(this.state.tableID);
      if (el) {
        fadeInOut(el, "500", true);
      }
    }
  };

  handleTRBtn = (url, row) => {
    this.setState({ itemForDeletionID: row.id, buttonEndpointUrl: url });

    const el = document.getElementById(this.state.tableID);
    if (el) {
      fadeInOut(el, "500", true);
    }
  };

  checkAllRows = () => {
    const rows = this.state.tableData.data;
    const rowIDs = [];

    this.setState(
      prevState => ({ listCheckBox: !prevState.listCheckBox }),
      () => {
        // toggle all rows
        if (this.state.listCheckBox) {
          rows.forEach(row => {
            row.checked = true;
            rowIDs.push(row.id);
          });
        } else {
          rows.forEach(row => {
            row.checked = false;
          });
        }

        this.setState(prevState => ({
          tableData: {
            ...prevState.tableData,
            data: rows,
          },
          alertVisiblity: false,
          rowIDs,
          somethingIsChecked: this.state.listCheckBox,
        }));
      }
    );
  };

  toggleCheckBox = rowID => {
    const rows = this.state.tableData.data;

    // check row
    rows.forEach(row => row.id === rowID && (row.checked = !row.checked));

    // check if row id is in the [rowIDs] state
    // if not add it
    let rowIDs = [];
    // TODO: includes() not supported by IE
    const entryIdExists = this.state.rowIDs.includes(rowID);

    if (entryIdExists) rowIDs = this.state.rowIDs.filter(id => id !== rowID);
    else rowIDs = [...this.state.rowIDs, rowID];

    this.setState({ rowIDs, alertVisiblity: false });
    this.checkIfChecked();
  };

  checkIfChecked = () => {
    const rows = this.state.tableData.data;
    this.setState({ somethingIsChecked: !!_find(rows, { checked: true }) });
  };

  // apply or remove filters
  handleFiltering = (filter, values) => {
    // if clear filters button is clicked values are empty.
    const clearFilter = _isEmpty(values);

    let { postData } = this;

    // if there is no filter list object yet
    if (_isEmpty(postData.filter)) postData.filter = {};

    if (clearFilter)
      // remove filter
      delete postData.filter[filter.key];
    else postData.filter[filter.key] = values.map(v => v.id);

    return this.fetchNewTable(postData);
  };

  onSearchTextChange = e => {
    const searchText = e.target.value;
    if ((this.state.searchText && !searchText) || (!this.state.searchText && searchText))
      this.setState({ searchText });

    if (searchText) this.handleSearch(searchText);
    else this.clearSearch();
  };

  clearSearch = () => {
    const input = this.searchInputRef.current;
    input.value = "";
    input.focus();
    this.setState({ searchText: "" });
    this.handleSearch("", true);
  };

  debouncedFetchTable = debounce(this.fetchNewTable, 500);
  cancelDebouncedSearch = () => {};

  handleSearch = (inputText, immediately) => {
    if (this.postData.search !== inputText.trim()) {
      const postData = { ...this.postData, search: inputText.trim() };
      if (immediately) this.cancelDebouncedSearch() || this.fetchNewTable(postData);
      else this.cancelDebouncedSearch = this.debouncedFetchTable(postData);
    } else this.cancelDebouncedSearch();
  };

  removeFilters = () => {
    let { postData } = this;

    if (!postData.search || !_isEmpty(postData.filter)) {
      postData.search = "";
      postData.filter = {};
      this.fetchNewTable(postData);
    }
  };

  successfullyRemoved = () => {
    this.setState({ deleteModalLoader: undefined, rowIDs: [], itemForDeletionID: null });
    // hide modal
    const el = document.getElementById(this.state.tableID);
    if (el) {
      fadeInOut(el, "500", false);
    }
  };

  confirmDelete = () => {
    const rows = this.state.tableData.data;
    const endPointUrl = this.state.buttonEndpointUrl;
    let idsToDelete = [];

    // if clicked on delete icon in table row
    if (this.state.itemForDeletionID) {
      idsToDelete.push(this.state.itemForDeletionID);
    } else {
      // if row is checked and delete icon is clicked in table header menu
      // collect selected ids
      idsToDelete = rows.filter(row => row.checked).map(row => row.id);
    }

    // show modal loader
    this.setState({ deleteModalLoader: true });

    // remove selected items
    AJAX()
      .delete(this._id, encodeURI(`${endPointUrl}?ids=${JSON.stringify(idsToDelete)}`))
      .then(res => {
        if (!res.data) return;

        // if items were successfully removed
        this.fetchNewTable(this.postData);

        this.setState({
          deleteModalLoader: false,
          listCheckBox: false,
        });
      });
  };

  handlePageChange = pageNumber => {
    let { postData } = this;
    if (pageNumber === postData.page) return;
    postData.page = pageNumber;

    // uncheck all checked items and add page number to postData
    this.setState({ rowIDs: [] });

    this.fetchNewTable(postData)
      // add data to redux
      .then(newTable => this.props.addEntryList(newTable, this.state.tableID));
  };

  handleOrderByColumn = (e, column) => {
    let {
      postData,
      postData: { order },
      orderClicks,
    } = this;

    order = Array.isArray(order) ? order : [];
    let newOrder = _find(order, { key: column.key });

    if (newOrder) {
      order = order.filter(o => o.key !== newOrder.key);
      newOrder.dir = newOrder.dir === "<" ? ">" : "<";
      orderClicks[newOrder.key] += 1;
    } else {
      orderClicks[column.key] = 1;
      newOrder = { key: column.key, dir: column.fst_dir || "<" };
    }

    order =
      e.ctrlKey || e.metaKey
        ? orderClicks[newOrder.key] > 2
          ? order
          : [...order, newOrder]
        : [newOrder];

    this.fetchNewTable({ ...postData, order });
  };

  tableBoxBtnClick = btn => {
    if (btn.kind === "form" && this.state.form) return this.setState({ toggleForm: true });

    if (btn.api && btn.api.url) {
      //this.setState({toggleForm: !this.state.toggleForm})

      // show loader
      this.setState({ [btn.id]: true });

      //  Fake data: 'https://alphaawards-aca8b.firebaseio.com/app/success.json'
      setTimeout(() => {
        AJAX()
          .get(this._id, btn.api.url)
          .then(res => {
            if (!res || !res.data) return;

            // hide loader
            let newState = { [btn.id]: false };
            // if response is form show it
            if (btn.kind === "form")
              newState = { ...newState, form: res.data.form, toggleForm: true };

            this.setState(newState);
          });
      }, 500);
    }
  };

  hideForm = e => {
    this.setState({ toggleForm: false });
  };

  handleFormSubmit = (data, res) => {};

  actionsMap = {
    delete: { Icon: "delete" },
    copy: { Icon: "copy" },
    print: { Icon: "delete" },
  };

  // RENDER JSX
  renderButtons = table => {
    return (
      table.batch_post_actions &&
      table.batch_post_actions.map(item => {
        const knownAction = this.actionsMap[item.key];

        if (knownAction && item.multiple) {
          return (
            <Popover key={item.key} msg={this.props.intl.formatMessage({ id: item.key })}>
              <div className="myTable__btnMenu-i" onClick={() => this.handleAction(item)}>
                {knownAction.Icon && <IconAria iconId={knownAction.Icon} />}
              </div>
            </Popover>
          );
        } else {
          return null;
        }
      })
    );
  };

  renderTRButtons = row => {
    const { batch_post_actions } = this.state.tableData;

    return (
      batch_post_actions &&
      batch_post_actions.map(item => {
        const knownAction = this.actionsMap[item.key];

        if (knownAction && !row.locked) {
          const msg = this.props.intl.formatMessage({ id: item.key });
          return (
            <Popover msg={msg} key={item.key}>
              <div
                className="table__btnMenu-i table__btnMenu-i-side"
                onClick={() => this.handleTRBtn(item.api_url, row)}
                onKeyPress={e => e.key === "Enter" && this.handleTRBtn(item.api_url, row)}
                tabIndex="0"
              >
                {knownAction.Icon && <IconAria iconId={knownAction.Icon} />}
              </div>
            </Popover>
          );
        } else {
          return null;
        }
      })
    );
  };

  renderFilters = () =>
    (this.state.tableData.filters || []).map(filter => (
      <Select
        key={filter.key}
        placeholder={filter.label}
        valuePropName="label"
        propertyName={filter.key}
        addClass="contentContainer__contentMenu-sideMenuEl"
        options={filter.options}
        addExtraStylesOnSelected
        resetOptions
        getValues={values => this.handleFiltering(filter, values)}
      />
    ));

  // const filters = jp.query(this.state.copyOfData, '$.filters[*]');

  // if(filters) {
  //   return filters.map(filter => {
  //     let optionsLocation = `$.data[*].${filter.data_path}`;
  //     let options = jp.query(this.state.copyOfData, optionsLocation);

  //     // remove duplicated values
  //     let uniqueArray = options.filter(function(item, pos) {
  //       return options.indexOf(item) == pos;
  //     })

  //     return (
  //       <Select
  //         placeholder={filter.label}
  //         propertyName={filter.key}
  //         addClass="contentContainer__contentMenu-sideMenuEl"
  //         firstOption="removeFilter"
  //         options={uniqueArray}
  //         addStyleOnSelected
  //         getLSValues={this.state.tableID}
  //         getValue={values => this.handleFiltering(filter, values)}
  //         key={filter.label}/>
  //     )
  //   })
  // }

  renderSearchInput = table =>
    table.no_search || (
      <div className={"searchInput contentContainer__contentMenu-sideMenuEl"}>
        <IconAria iconId="search" className="searchInput__icon" test="searchIcon" />
        <input
          aria-label={this.props.intl.formatMessage({ id: "search" })}
          ref={this.searchInputRef}
          onKeyPress={e => e.key === "Enter" && this.handleSearch(e.target.value, true)}
          onKeyDown={e => e.key === "Escape" && (this.clearSearch() || e.target.blur())}
          onChange={this.onSearchTextChange}
          className={classes("searchInput__input", this.state.searchText && "searchInput--active")}
          placeholder={this.props.intl.formatMessage({ id: "search" })}
        />
        {this.state.searchText && (
          <div
            className="searchInput__closeIcon"
            tabIndex="0"
            onKeyPress={e => e.key === "Enter" && this.clearSearch()}
            onClick={this.clearSearch}
          >
            <IconAria iconId="close" />
          </div>
        )}
      </div>
    );

  renderTable = table => {
    // render head row data
    const thead = (
      <thead>
        <tr>{table.columns.map(this.renderTH)}</tr>
      </thead>
    );

    // render body rows
    const bodyRows = table.data.map((row, index) => (
      <tr
        key={Math.random()}
        className={classes(
          "myTable__tr",
          row.checked && "myTable__row-active",
          row.status === "confirmed" && "myTable__tr-submitted"
        )}
      >
        {this.renderTD(table, row, index, table.columns)}
        <td className="myTable__tr-menu">{this.renderTRButtons(row)}</td>
      </tr>
    ));

    if (this.state.tbodyLoader) {
      return (
        <div>
          <table
            cellSpacing="0"
            role="presentation"
            className="myTable"
            data-test="entryTableLoader"
          >
            {thead}
          </table>
          <ContentLoader />
        </div>
      );
    } else {
      return (
        <div>
          <div
            className={classes(
              "myTable__btnMenu",
              this.state.somethingIsChecked && "myTable__btnMenu-animate"
            )}
          >
            {this.renderButtons(table)}
          </div>
          <table cellSpacing="0" role="presentation" className="myTable" data-test="entryTable">
            {thead}
            <tbody>{bodyRows}</tbody>
          </table>
        </div>
      );
    }
  };

  renderTH = (item, index) => {
    const maybeHideSmall = item.visibility >= 0 || "r-hideOnSmall";
    const commonClass =
      "myTable__headTitle-small myTable__title-left myTable__headTitle myTable__headTitle-first";

    if (item.key === "checkAll") {
      return (
        index === 0 && (
          <th key={item.key} className={classes(commonClass, maybeHideSmall)}>
            <span className="myTable__checkbox">
              <CheckBox toggleIcons={this.checkAllRows} checked={this.state.listCheckBox} />
            </span>
          </th>
        )
      );
    } else if (item.key === "preview_image") {
      return <th key={item.key} className={classes(commonClass, maybeHideSmall)}></th>;
    } else {
      return (
        <th
          key={item.key}
          onClick={evt => this.handleOrderByColumn(evt, item)}
          style={{ width: item.size }}
          className={classes(commonClass, item.sortable && "myTable__sortable", maybeHideSmall)}
        >
          {item.label}
          {item.sortable && (
            <span className="myTable__arrows">
              {item.order &&
                (item.order === "<" ? (
                  <IconAria iconId="arrowDropUp" className="myTable__arrows-1" />
                ) : (
                  <IconAria iconId="arrowDropDown" className="myTable__arrows-2" />
                ))}
            </span>
          )}
        </th>
      );
    }
  };

  renderTD = (table, row, index, columns) => {
    return columns.map(column => {
      // Get value from row via json path query.
      const jpValues = jp.query(row, `$.${column.jpath || column.key}`);
      // Not all queries return just one value. Store first value for most common cases.
      const jpValue0 = jpValues[0];

      let tdValue,
        tdClass = "";

      if (column.key === "collections") {
        const onClick = (evt, i) => this.handleFiltering({ key: "category" }, [row.collections[i]]);

        tdClass = "categories";
        tdValue = jpValues.map((v, i) => (
          <Fragment key={v}>
            {!i || ", "}
            <span
            // onClick={e => onClick(e, i)}
            // tabIndex="0"
            // onKeyPress={e => e.key === "Enter" && onClick(e, i)}
            >
              {v}
            </span>
          </Fragment>
        ));
      } else if (column.key === "assets") {
        const popoverMsg = ["image", "audio", "video", "total"]
          .map(counterName => {
            const counter = jpValue0[counterName + "_count"];
            return counter >= 0
              ? this.props.intl.formatMessage({ id: counterName + "Count" }) + ": " + counter
              : "";
          })
          .join(", ");

        const renderCount = (count, icon) =>
          count >= 0 && (
            <span className="myTable__mediaIcon">
              {icon} <span>{count}</span>
            </span>
          );

        tdValue = (
          <Popover msg={popoverMsg}>
            {/*{renderCount(jpValue0.image_count, <IconAria iconId="image" />)}*/}
            {/*{renderCount(jpValue0.audio_count, <IconAria iconId="music" />)}*/}
            {/*{renderCount(jpValue0.video_count, <IconAria iconId="video" />)}*/}
            {renderCount(jpValue0.total_count, <IconAria iconId="file" />)}
          </Popover>
        );
      } else if (column.type === "image") {
        tdClass = "myTable__img";
        tdValue =
          !jpValue0 === "" ? (
            <div className="myTable__image-replace">
              <IconAria iconId="image" />
            </div>
          ) : (
            <Popover imageInspector>
              <div
                style={{ backgroundImage: `url(${jpValue0})` }}
                img-url={jpValue0}
                className="myTable__image"
              ></div>
            </Popover>
          );
      } else if (column.type === "datetime" || column.type === "date") {
        const format = column.format || column.type === "date" ? "DD.MM.YY" : "DD.MM.YY - HH:mm";
        tdValue = formatDateTime(jpValue0, format);
      } else if (column.key === "status") {
        tdClass = row.status === "confirmed" && "myTable__tr-submittedText";
        tdValue = <span>{jpValue0}</span>;
      } else if (column.type === "boolean") {
        const isBool = typeof jpValue0 === "boolean";
        tdValue = (
          <span>
            {isBool ? (
              jpValue0 ? (
                <Icon
                  iconId="checkLine"
                  addClass="c1"
                  style={{ width: "1.3rem", height: "1.3rem" }}
                />
              ) : (
                "– "
              )
            ) : (
              "–"
            )}
          </span>
        );
      } else if (column.type === "progress") {
        tdValue = jpValue0 === null ? "–" : jpValue0.toFixed(1);
      } else if (column.key === "checkAll") {
        tdValue = (
          <span className="myTable__checkbox">
            <CheckBox toggleIcons={() => this.toggleCheckBox(row.id)} checked={row.checked} />
          </span>
        );
      } else {
        const { link } = column;

        if (link) {
          const role = this.props.user.role_selected;
          const slugMap = { entries: "project-overview", users: "user", cases: "case" };
          const page = slugMap[link.slug] || "unknown";
          const href = `/ui/${role}/${page}/${row[link.key]}`;

          if (this.props.linkCallBack) {
            tdValue = (
              <span className="myTable__a" onClick={() => this.props.linkCallBack(row[link.key])}>
                {jpValue0}
              </span>
            );
          } else {
            tdValue = (
              <Link className="myTable__a" to={href}>
                {jpValue0}
              </Link>
            );
          }
        } else {
          tdValue = wrapArray(jpValues)
            .map(x => (x === null ? "–" : x))
            .join(", ");
        }
      }

      const maybeHideSmall = column.visibility >= 0 || "r-hideOnSmall";

      return (
        <td key={column.key} className={classes(tdClass, maybeHideSmall)}>
          {tdValue}
        </td>
      );
    });
  };

  renderPegination = () => {
    const { page_number, page_size, total_entries } = this.state.tableData;

    return (
      total_entries >= 0 && (
        <div className="pagination__container">
          <Pagination
            activePage={page_number}
            itemsCountPerPage={page_size}
            totalItemsCount={total_entries}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange}
          />
        </div>
      )
    );
  };

  render() {
    if (Array.isArray(this.props.data.data) && this.props.data.data.length === 0)
      return <ColorAlerter important intlMsg="noEntriesInTable" toggle={true} />;

    if (!this._tableLoaded) return null;

    const table = this.state.tableData;
    const hasBachPostActions = table.batch_post_actions && table.batch_post_actions.length > 0;

    return (
      <div data-name="AlphaTable">
        <div className={classes(this.state.tableLoader || "u-hide")}>
          <ContentLoader full />
        </div>
        <div className={classes(this.state.tableLoader && "u-hide")}>
          <ContentContainer
            titleType={this.props.titleType || "h2"}
            stats={table.stats}
            title={table.header || this.props.intl.formatMessage({ id: "projectList" })}
          >
            <div className={classes("myTable__menu", hasBachPostActions && "myTable__menu-expand")}>
              <div className="myTable__menu-nonFilters">
                <div className="myTable__menu-btns">
                  {table.filters.length <= 2 && this.renderFilters(table)}
                  {/* {this.renderButtons(table)} */}
                  {/* {table.filters.length > 1 ?
                  <TableBtn
                    text={'Remove Filters'}
                    clicked={this.removeFilters}
                    loading={false}
                    test="deleteBtn">
                  </TableBtn>
                : null} */}
                </div>

                <div
                  className={classes(
                    "myTable__menu-search",
                    table.filters.length > 2 && "myTable__menu-search-fullWidth"
                  )}
                >
                  {this.renderSearchInput(table)}
                </div>
              </div>

              <div className={"myTable__menu-filters"}>
                {table.filters.length > 2 && this.renderFilters(table)}
              </div>
            </div>

            <div>
              <div className={classes("myTable__form", this.state.toggleForm || "u-hide")}>
                <IconAria iconId="close1" className="myTable__form-x" clicked={this.hideForm} />
                {this.state.toggleForm && this.state.form && (
                  <TableForm form={this.state.form} info={this.props.info} />
                )}
              </div>

              <div className="myTable__buttons">
                {(table.buttons || []).map(btn => (
                  <Button
                    {...btn}
                    clicked={() => this.tableBoxBtnClick(btn)}
                    loader
                    showLoader={this.state[btn.id]}
                    key={btn.id}
                  />
                ))}
              </div>
            </div>

            <TextAlerter warning text="checkSomething" toggle={this.state.alertVisiblity} />

            {table.data.length !== 0 && this.renderTable(table)}

            {this.state.noResultsFound && (
              <TextAlerter
                important
                text={this.props.intl.formatMessage({ id: "noResultsFound" })}
                toggle={true}
              />
            )}

            {!this.state.noResultsFound && this.renderPegination()}

            <Modal
              promptModal
              loaderDone={this.successfullyRemoved}
              loader={this.state.deleteModalLoader}
              intl
              yesMsg="yes"
              noMsg="no"
              id={this.state.tableID}
              yes={this.confirmDelete}
              msg="areYouSureDeleteEntries"
            />
          </ContentContainer>
        </div>
      </div>
    );
  }
}

AlphaTable.propTypes = {
  tableData: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  info: state.app.info,
});

export default injectIntl(connect(mapStateToProps, { addEntryList })(AlphaTable));
