import axios from "axios";
import AA_GLOBAL from "./globals/globals";

export let axiosWithAuth = axios.create({
  baseURL: AA_GLOBAL.baseURL,
  headers: {
    gcommit: process.env.REACT_APP_GIT_COMMIT || "",
    authorization: AA_GLOBAL.cookies.get("auth_token"),
    "accept-language": AA_GLOBAL.cookies.get("lang") || "*",
  },
});

axiosWithAuth.CancelToken = axios.CancelToken;

axiosWithAuth.isCancel = axios.isCancel;

axiosWithAuth.interceptors.response.use(
  res => res,
  error => {
    switch (error.response && error.response.status) {
      case 401:
        // Unauthorized
        AA_GLOBAL.cookies.remove("auth_token", { path: "/" });
        window.location.pathname = "/ui/auth";
        break;
      case 403:
        // Forbidden
        // FIXME: shouldn't necessarily mean that the user did something unauthorized
        AA_GLOBAL.cookies.remove("auth_token", { path: "/" });
        window.location.pathname = "/ui/403";
        break;
      default:
    }
    return Promise.reject(error);
  }
);
