/*jshint esversion: 6 */
import * as Sentry from "@sentry/browser";
import "flatpickr/dist/l10n/de";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./main.css";
import "./main.dashboard.css";
import { addLocaleData } from "react-intl";
import ReduxWrapper from "./hoc/ReduxWrapper"; // REDUX
import "promise-polyfill/src/polyfill";
import "./polyfills/flatMap";

// define languages you what to use
import en from "react-intl/locale-data/en";
import de from "react-intl/locale-data/de";
import ru from "react-intl/locale-data/ru";
addLocaleData([...en, ...de, ...ru]);

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: "https://909e014843fe486d93e6e3ad8b2620b9@sentry1.alpha-awards.com/5",
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });

// if our app supports the browser, render it
if (!localStorage.getItem("browserSupport")) {
  const root = React.createElement(ReduxWrapper, {}, React.createElement(App));
  ReactDOM.render(root, document.getElementById("root"));
}
