import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import AlphaLogo from "../img/logo.png";
import { MdEmail, MdLock } from "react-icons/md";

// COMPONENTS
import Button from "../components/buttons/Button";
import IconAria from "../components/icons/IconAria";
import AlphaForm from "../components/forms/AlphaForm";
import ContentGenerator from "../components/layout/ContentGenerator";

// REDUX
import { connect } from "react-redux";

// GLOBAL
import AA_GLOBAL from "../globals/globals";

// FUNCTIONS
import AJAX from "../functions/ajax";

export class Registration extends Component {
  _id = Math.random();

  state = {
    loader: false,
    confirmedAtVisibility: false,
    form: {
      components: [
        {
          id: "3f0412357u",
          type: "email",
          name: "email",
          value: "",
          tabIndex: "1",
          icon: <IconAria iconId="email" />,
          additional: {
            placeholder: "email",
          },
          validate: {
            valid: false,
            regex: AA_GLOBAL.emailRegex,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterEmail",
              invalidValue: "invalidEmail",
            },
          },
        },
        {
          id: "fs13fht46",
          type: "password",
          name: "password",
          value: "",
          tabIndex: "2",
          repeat: "firstPass",
          icon: <IconAria iconId="password" />,
          additional: {
            placeholder: "password",
          },
          validate: {
            valid: false,
            regex: AA_GLOBAL.userPassRegex,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterPassword",
              invalidValue: "userPasswordValidation",
            },
          },
        },
        {
          id: "s0f873136",
          type: "password",
          name: "password_confirmation",
          value: "",
          tabIndex: "3",
          icon: <IconAria iconId="password" />,
          repeat: "secondPass",
          additional: {
            placeholder: "repeatPassword",
          },
          validate: {
            valid: false,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterPassword",
              repeatPassword: "repeatPassNotMatch",
            },
          },
        },
        // {
        //   id: 'bfsfgw2',
        //   type: 'checkbox',
        //   name: 'accept_tos',
        //   checked: false,
        //   hideStar: true,
        //   additional: {
        //     urlLabel: {
        //       url: '#empty',
        //       name: 'terms'
        //     }
        //   },
        //   validate: {
        //     valid: false,
        //     errMsg: '',
        //     errMsgs: {
        //       fieldRequiredOrEmpty: 'acceptTerms',
        //     }
        //   }
        // },
        // {
        //   id: 'sffawd57',
        //   type: 'checkbox',
        //   name: 'accept_gdpr',
        //   checked: false,
        //   hideStar: true,
        //   additional: {
        //     urlLabel: {
        //       url: '#empty',
        //       name: 'privacy'
        //     }
        //   },
        //   validate: {
        //     valid: false,
        //     errMsg: '',
        //     errMsgs: {
        //       fieldRequiredOrEmpty: 'acceptPrivacy',
        //     }
        //   }
        // }
      ],
    },
  };

  UNSAFE_componentWillMount() {
    // make checkboxes
    if (
      this.props.data &&
      this.props.data.forms.registration_requirements &&
      this.props.data.forms.registration_requirements.length > 0
    ) {
      const formData = this.state.form.components;
      this.props.data.forms.registration_requirements.forEach(item => {
        const checkbox = {};
        checkbox.id = item.id;
        checkbox.type = "checkbox";
        checkbox.name = item.name;
        checkbox.tabindex = "4";
        checkbox.checked = false;
        checkbox.additional = item.url
          ? { urlLabel: { url: item.url, name: item.label } }
          : { label: item.label };

        if (item.required) {
          checkbox.validate = {
            valid: false,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "setCheckbox",
            },
          };
        }

        formData.push(checkbox);
      });

      this.setState({ form: { components: formData } });
    }
  }

  componentWillUnmount() {
    AJAX().cancelRequest(this._id);
  }

  handleRegistration = formData => {
    if (formData) {
      this.setState({ loader: true });

      AJAX()
        .register(this._id, this.props, { registration: formData })
        .then(res => {
          if (res) {
            this.setState({ loader: false });
            const response = res.data;

            if (response.status === "error") {
              // show error message
              this.setState({ form: { errMsg: response.key } });
            } else if (response.status === "success") {
              // hide form and show confirm email message
              this.setState({ confirmedAtVisibility: true, confirmEmailMsg: response.key });
              // show login first on page reload
              //localStorage.setItem('login', true)
            }
          }
        });
    }
  };

  getChangeAuth = name => () => this.props.changeAuth(name);

  render() {
    const forms = this.props.data && this.props.data.forms;
    const TagName =
      this.props.app.screenSize === "mobile" || this.props.app.screenSize === "tablet"
        ? "h1"
        : "h2";

    return (
      <div>
        <div className="loginForm" id="registerPageForm">
          <div
            data-test="coverLoader"
            className={
              "cover cover__loader loginForm__loaderCon " + (this.state.loader ? "" : "u-hide")
            }
          >
            <div className="circleLoader-extraLarge loginForm__loader"></div>
          </div>

          <div
            data-test="coverMsgContainer"
            className={
              "cover loginForm__confirm " + (this.state.confirmedAtVisibility ? "" : "u-hide")
            }
          >
            <div className="loginForm__title">
              <img
                data-test="logo"
                src={AlphaLogo}
                alt="alpha awards logo"
                className="loginForm__title-logo"
              />
              <TagName className="title--big" data-test="title">
                <FormattedMessage id="register" />
              </TagName>
            </div>
            {forms && forms.header_message && forms.header_message.form === "register" && (
              <div className="">
                <ContentGenerator data={forms.header_message.html} />
              </div>
            )}
            <div className="u-mb">
              {this.state.confirmEmailMsg ? (
                <strong>
                  <FormattedMessage id={this.state.confirmEmailMsg} />.
                </strong>
              ) : null}
              <div className="cover__buttons">
                <Button
                  labelID="login"
                  tabIndex="5"
                  className="btn btn__main btn--mainClr loginForm__form-btn"
                  clicked={() => this.props.changeAuth("login")}
                />
              </div>
            </div>
          </div>

          <div className="loginForm__title" data-test="formTitleCon">
            <img
              src={AlphaLogo}
              alt="alpha awards logo"
              data-test="logo"
              className="loginForm__title-logo"
            />
            <TagName className="title--big" data-test="title">
              <FormattedMessage id="register" />
            </TagName>
          </div>
          {forms && forms.header_message && forms.header_message.form === "register" && (
            <div className="">
              <ContentGenerator data={forms.header_message.html} />
            </div>
          )}

          <AlphaForm
            test="AlphaForm"
            form={this.state.form}
            getData={this.handleRegistration}
            formId="register"
          />

          <div className="loginForm__serverErr" data-test="loginErrMsg">
            {this.state.emailExists}
          </div>

          <div className="loginForm__registrationSeparator"></div>

          <Button
            type="submit"
            tabIndex="5"
            labelID="register"
            addClass="loginForm__form-btn"
            formID="register"
            test="mainBtn"
          />

          <Button
            labelID="login"
            tabIndex="6"
            clicked={this.getChangeAuth("login")}
            addClass="loginForm__form-btn button--secondary"
          />

          <div className="loginForm__footer" data-test="formFooter">
            {/* <span
            className="button button__text"
            onClick={() => this.props.changeAuth('login')}
            data-test="login"><FormattedMessage id="login"/>&nbsp;– </span> */}
            <span
              className="button button__text r-showOnSmall"
              onClick={() => this.props.changeAuth("info")}
              data-test="info"
            >
              <FormattedMessage id="info" />
              &nbsp;–{" "}
            </span>
            <span
              tabIndex="10"
              className="button button__text"
              onClick={() => this.props.changeAuth("retrieve")}
              onKeyUp={e => e.key === "Enter" && this.props.changeAuth("retrieve")}
              data-test="forgotPassword"
            >
              <FormattedMessage id="forgotPassword" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Registration.propTypes = {
  changeAuth: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  info: state.app.info,
  app: state.app,
});

export default injectIntl(withRouter(connect(mapStateToProps)(Registration)));
