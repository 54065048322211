import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import AlphaLogo from "../img/logo.png";
import AlphaLogoJudge from "../img/logo_judge.png";
import { MdEmail } from "react-icons/md";

// FUNCTIONS
import AJAX from "../functions/ajax";

// COMPONENTS
import Button from "../components/buttons/Button";
import IconAria from "../components/icons/IconAria";
import AlphaForm from "../components/forms/AlphaForm";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// REDUX
import { connect } from "react-redux";

export class ForgotPassword extends Component {
  _id = Math.random();

  state = {
    formErrMsg: "",
    email: {
      value: "",
      errMsg: null,
    },

    loader: false,
    confirmedAtVisibility: false,
    form: {
      components: [
        {
          id: "if432k3",
          type: "email",
          tabIndex: "1",
          name: "email",
          value: "",
          icon: <IconAria iconId="email" />,
          additional: {
            placeholder: "email",
          },
          validate: {
            valid: false,
            regex: AA_GLOBAL.emailRegex,
            errMsg: "",
            errMsgs: {
              fieldRequiredOrEmpty: "enterEmail",
              invalidValue: "invalidEmail",
            },
          },
        },
      ],
    },
  };

  componentWillUnmount() {
    AJAX().cancelRequest(this._id);
  }

  handleReset = email => {
    if (email) {
      this.setState({ loader: true });
      AJAX()
        .forgotPassword(this._id, this.props, email)
        .then(res => {
          if (res) {
            // hide form and show confirm email message
            this.setState({ confirmedAtVisibility: true, confirmEmailMsg: res.data.key });
          }
        });
    }
  };

  render() {
    const isReviewer = ["judge", "reviewer"].includes(this.props.match.params.module);
    const TagName =
      this.props.app.screenSize === "mobile" || this.props.app.screenSize === "tablet"
        ? "h1"
        : "TagName";

    return (
      <div>
        <div className="loginForm" id="forgotPasswordPageForm">
          <div
            data-test="coverLoader"
            className={
              "cover cover__loader  loginForm__loaderCon " + (this.state.loader ? "" : "u-hide")
            }
          >
            <div className="circleLoader-extraLarge loginForm__loader"></div>
          </div>

          <div
            data-test="coverMsgContainer"
            className={
              "cover loginForm__confirm " + (this.state.confirmedAtVisibility ? "" : "u-hide")
            }
          >
            <div className="loginForm__title">
              <img
                data-test="logo"
                src={isReviewer ? AlphaLogoJudge : AlphaLogo}
                alt="alpha awards logo"
                className="loginForm__title-logo"
              />
              <TagName className="title--big" data-test="title">
                <FormattedMessage id="reset" />
              </TagName>
            </div>
            <div className="u-mb">
              {this.state.confirmEmailMsg ? (
                <strong>
                  <FormattedMessage id={this.state.confirmEmailMsg} />.
                </strong>
              ) : null}
              <div className="cover__buttons">
                <Button
                  labelID="login"
                  tabIndex="2"
                  className="button button__main btn--mainClr loginForm__form-btn"
                  clicked={() => this.props.changeAuth("login")}
                />
              </div>
            </div>
          </div>

          <div className="loginForm__title" data-test="formTitleCon">
            <img
              src={isReviewer ? AlphaLogoJudge : AlphaLogo}
              alt="alpha awards logo"
              data-test="logo"
              className="loginForm__title-logo"
            />
            <TagName className="title--big" data-test="title">
              <FormattedMessage id="cantLogin" />
            </TagName>
          </div>
          <div className="loginForm__retrivePassMsg">
            <FormattedMessage id="forgotPassMsg" />
          </div>
          <AlphaForm
            form={this.state.form}
            getData={this.handleReset}
            formId="retrieve"
            test="AlphaForm"
          />

          <div className="loginForm__loginSeparator"></div>

          <Button
            type="submit"
            tabIndex="2"
            labelID="requestNewPassword"
            addClass="loginForm__form-btn"
            formID="retrieve"
            test="mainBtn"
          />

          <div className="loginForm__footer" data-test="formFooter">
            <span
              tabIndex="10"
              className="button button__text"
              onClick={() => this.props.changeAuth("login")}
              onKeyUp={e => e.key === "Enter" && this.props.changeAuth("login")}
              data-test="login"
            >
              {" "}
              <FormattedMessage id="toLogin" />
            </span>
            {this.props.data && !this.props.data.forms.hide_registration ? (
              <span>
                {" "}
                -
                <span
                  tabIndex="10"
                  className="button button__text"
                  onClick={() => this.props.changeAuth("register")}
                  onKeyUp={e => e.key === "Enter" && this.props.changeAuth("register")}
                  data-test="registration"
                >
                  {" "}
                  <FormattedMessage id="register" />
                </span>
              </span>
            ) : null}
            <span
              className="button button__text r-showOnSmall"
              onClick={() => this.props.changeAuth("info")}
              data-test="info"
            >
              {" "}
              - <FormattedMessage id="info" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  changeAuth: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  info: state.app.info,
  app: state.app,
});

export default injectIntl(withRouter(connect(mapStateToProps)(ForgotPassword)));
