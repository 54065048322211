import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import renderLangString from "../languageStrings";

// COMPONENTS
import ModalBack from "../components/modals/ModalBack";
import ModalPortal from "../components/modals/ModalPortal";
import ErrorPopup from "../components/popups/ErrorPopup";

// REDUX
import { connect } from "react-redux";
import { showMobMenu, hideMenu, resetStoreProps, setPageWidth } from "../actions/appActions";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// FUNCTIONS
import S from "../functions/select";
import { getWidth } from "../functions/getPageDimesions";

export class AppHOC extends Component {
  state = {
    touchStart: 0,
    swipeLength: 200,
  };

  getUrlRole = () => this.props.history.location.pathname.split("/")[2];
  isReviewer = role => ["judge", "reviewer"].includes(role);

  UNSAFE_componentWillMount() {
    const urlRole = this.getUrlRole();
    if (!this.isReviewer(urlRole)) {
      // reset store props
      this.props.resetStoreProps();
    }

    // enforce profile on login if enforce_profile = true
    // if (AA_GLOBAL.cookies.get("auth_token") && this.props.user.enforce_profile) {
    //   this.props.history.push(`/ui/${urlRole}/profile/edit`);
    // }
    const isInfoPage = this.props.history.location.pathname == "/ui/info";

    if (AA_GLOBAL.cookies.get("auth_token") && this.props.user.enforce_profile && !isInfoPage) {
      this.props.history.push(`/ui/${urlRole}/profile/edit`);
    }
  }

  componentDidMount() {
    const body = S("body")[0];
    // check for app theme and add the class `theme-judge` if it is a new theme
    if (this.isReviewer(this.getUrlRole())) {
      // add theme to the body
      body.classList.add("theme-judge", "style-fix");
    } else {
      body.classList.remove("theme-judge", "style-fix");
    }

    // set html lang tag
    const lang = AA_GLOBAL.cookies.get("lang").slice(0, 2).toLowerCase();
    document.documentElement.setAttribute("lang", lang);

    // set the screen size
    this.setScreenSize();
    window.addEventListener("resize", this.setScreenSize);

    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    this.setState({ isConnectionLost: true });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenSize);
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  setScreenSize = () => {
    const pageWidth = getWidth();

    const screenSizes = {
      desktop: 980,
      tablet: 736,
      mobile: 300,
    };

    if (pageWidth > screenSizes.desktop) {
      if (!this.props.app.screenSize || this.props.app.screenSize !== "desktop")
        this.props.setPageWidth("desktop");
    } else if (pageWidth > screenSizes.tablet) {
      if (!this.props.app.screenSize || this.props.app.screenSize !== "tablet")
        this.props.setPageWidth("tablet");
    } else if (pageWidth > screenSizes.mobile) {
      if (!this.props.app.screenSize || this.props.app.screenSize !== "mobile")
        this.props.setPageWidth("mobile");
    }
  };

  setSwipeStartPosition = e => {
    this.setState({ touchStart: e.touches[0].clientX });
  };

  swipeToggleMobMenu = e => {
    // dont show the mob menu on the pages that dont have it
    if (!document.getElementById("mobileNav")) {
      return false;
    } else {
      let touchEndDone = e.changedTouches[0].clientX;
      if (this.state.touchStart > touchEndDone + this.state.swipeLength) {
        // hide mob menu if visible
        if (this.props.mobMenuVisible) {
          this.props.hideMenu();
        }
      } else if (this.state.touchStart < touchEndDone - this.state.swipeLength) {
        // if gallery is on fullscreen dont run this function
        // it will show mob menu in background
        if (document.getElementById("mobileNav")) {
          var style = window.getComputedStyle(document.getElementById("mobileNav"));
          if (
            !this.props.galleryFullscreenState &&
            style.transform === "matrix(1, 0, 0, 1, -290, 0)"
          ) {
            this.props.showMobMenu();
          }
        }
      }
    }
  };

  domClicked = e => {
    // hide popovers on document click
    // if(!e.target.hasAttribute('data-popover')) {
    //   const popovers = document.getElementsByClassName('popoverText');
    //   for(let i = 0; i < popovers.length; i++) {
    //     popovers[i].classList.add('u-hide')
    //   }
    // }
  };

  render() {
    if (this.state.isConnectionLost) {
      return (
        <ModalPortal>
          <ErrorPopup
            text={renderLangString({ id: "$_c9" })}
            iconId="noConnection"
            reloadPageBtn
            title={renderLangString({ id: "$_c8" })}
          />
        </ModalPortal>
      );
    }
    if (this.props.info) {
      return (
        <div
          onClick={this.domClicked}
          onTouchStart={this.setSwipeStartPosition}
          onTouchEnd={this.swipeToggleMobMenu}
        >
          {this.props.children}
          <ModalBack />
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  galleryFullscreenState: state.app.gallery.fullScreen,
  mobMenuVisible: state.app.mobMenuDisplay,
  info: state.app.info,
  user: state.user,
  session: state.user.session,
  app: state.app,
});

export default withRouter(
  connect(mapStateToProps, { showMobMenu, hideMenu, resetStoreProps, setPageWidth })(AppHOC)
);
